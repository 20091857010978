<!--
 * @Author: yangliao
 * @Date: 2020-11-03 08:22:32
 * @LastEditTime: 2020-12-24 08:16:02
 * @LastEditors: Please set LastEditors
 * @Description: 用水报装
 * @FilePath: /netHallOfficialAccounts/src/views/Business/Expand/InstallWater.vue
-->
<template>
  <div>
    <div>
      <business-status
        v-if="statusInfor"
        :statusInfor="statusInfor"
        :busHandlingId="busHandlingId"
      />
      <div class="real-infor">
        <van-form ref="realInfo">
          <!-- <van-field readonly :label="labelName" input-align="right">
            <template #input>
              <span>{{ realName.contacts }}</span>
            </template>
          </van-field> -->
          <van-field
            :label="labelName"
            name="contacts"
            :input-align="realAlign"
            v-model="realName.contacts"
            :placeholder="`请填写${labelName}`"
            :rules="[
              { required: true },
              { pattern: /^[\u4e00-\u9fa5]{2,30}$/, message: '必须是长度为2到30的字符串！' }
            ]"
          />
          <van-field
            label="手机号"
            name="telephone"
            :input-align="realAlign"
            v-model="realName.telephone"
            :placeholder="`请填写${labelName}手机号`"
            :rules="[
              { required: true },
              { pattern: /^((13\d)|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[0,1,3,5,7,8])|(18[0-9])|(19[8,9]))\d{8}$/, message: '请输入正确手机号码' }
            ]"
          />
          <!-- <van-field
            label="身份证"
            name="idNo"
            :input-align="realAlign"
            v-model="realName.idNo"
            :placeholder="`请填写${labelName}身份证`"
            :rules="[
              { required: true },
              { pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/, message: '请输入正确身份证号' }
            ]"
          /> -->
        </van-form>
      </div>
    </div>
    <div class="vant-parser">
      <parser
        v-if="formConf"
        ref="parser"
        :key="key2"
        :form-conf="formConf"
        @submit="sumbitForm1"
        @failed="failedForm"
      />
    </div>
    <!-- [nh_00]办理中状态显示 -->
    <div class="footer" v-if="this.businessState === 'see' && stateBoolean">
      <div class="btns">
        <van-button plain type="info" @click="showCancelReport" style="margin-right: 10px">
          取消办理
        </van-button>
        <van-button plain type="info" @click="updateInfo">修改信息</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';
import Parser from '@/components/vant-parser/Parser';
import {
  addBusHandling,
  selectHandUser,
  selectBusHandlingById,
  updateBusHandling,
  cancelReport,
} from '@/api/business';
import { selectMaxRelease } from '@/api/feedback';
import BusinessStatus from '../components/BusinessStatus';
export default {
  components: {
    Parser,
    BusinessStatus,
  },
  data() {
    return {
      key2: +new Date(),
      formConf: null,
      businessCode: null,
      moduleCode: null,
      custReadingId: null,
      busHandlingId: null,
      /* businessState: add-新增；see-查看；edit-修改 */
      businessState: null,
      /* 页面修改，取消 */
      stateBoolean: false,
      formReleaseId: null,
      /* 实名认证信息 */
      realName: {
        contacts: null,
        telephone: null,
        // idNo: null,
      },
      /* 业务办理信息 */
      statusInfor: null,
      labelName: '办理人',
    };
  },
  computed: {
    ...mapState('user', {
      homeInfo: (state) => state.homeInfo,
      businessNews: (state) => state.businessNews,
    }),
    realAlign() {
      if (this.businessState !== 'see') {
        return 'left';
      }
      return 'right';
    },
  },
  mounted() {
    this.into();
  },
  methods: {
    /* 页面初始化 */
    into() {
      if (this.businessNews && this.businessNews.moduleCode) {
        this.businessCode = this.businessNews.businessCode;
        this.moduleCode = this.businessNews.moduleCode;
        this.custReadingId = this.businessNews.custReadingId;
        this.businessState = this.businessNews.businessState;
        // 个人： 户主 企业： 办理人
        if (
          this.moduleCode &&
          this.moduleCode.length > 6 &&
          this.moduleCode.substring(0, 6) === '000102'
        ) {
          this.labelName = '办理人';
        }
        this.busHandlingId =
          this.businessNews.busHandlingId !== undefined ? this.businessNews.busHandlingId : null;
        this.selectMaxRelease();
        if (this.businessState === 'add') {
          this.selectHandUser();
        }
        if (this.businessNews.moduleName !== undefined && this.businessNews.moduleName) {
          document.title = this.businessNews.moduleName;
        } else {
          document.title = '用水报装';
        }
      } else {
        Toast('获取业务类型信息失败，请返回重新选择！');
      }
    },
    failedForm() {
      this.$refs['realInfo'].validate().then((res) => {
      }).catch((error) => {
      });
    },
    /* 页面提交 */
    sumbitForm1(data) {
      this.$refs['realInfo'].validate().then((res) => {
        let formData = { ...data, ...this.realName };
        console.log(formData, 'formData')
        if (this.businessState === 'add') {
          let submitArg = {
            businessCode: this.businessCode,
            moduleCode: this.moduleCode,
            custReadingId: this.custReadingId,
            isReading: true,
            formReleaseIds: this.formReleaseId,
            formContent: JSON.stringify(formData),
            verification: {
              moduleCode: this.moduleCode,
            },
          };
          this.addBusHandling(submitArg);
        } else {
          let updateArg = {
            busHandlingId: this.busHandlingId,
            formReleaseIds: this.formReleaseId,
            formContent: JSON.stringify(formData),
            verification: {
              moduleCode: this.moduleCode,
            },
          };
          this.updateBusHandling(updateArg);
        }
      }).catch((error) => {

      });
    },
    // 取消
    showCancelReport() {
      Dialog.confirm({
        message: '您确定取消申请吗？',
        confirmButtonText: '取消申请',
        confirmButtonColor: '#3C7EF0',
        cancelButtonText: '点错了',
        cancelButtonColor: '#767E8C',
      })
        .then(() => {
          this.cancelReport();
        })
        .catch(() => {
          console.log('取消申请');
        });
    },
    /* 取消 */
    async cancelReport() {
      let arg = this.busHandlingId;
      const { status, resultData } = await cancelReport(arg);
      if (status === 'complete') {
        Toast('该业务已取消');
        // 回显页面
        this.setBusinessNews('see');
        this.key2 = +new Date();
        this.formConf = { ...this.formConf, readonly: true };
        this.selectBusHandlingById(this.busHandlingId);
      }
    },
    /* 修改信息 */
    async updateInfo(data) {
      this.setBusinessNews('edit');
      this.key2 = +new Date();
      this.formConf = { ...this.formConf, readonly: false };
      this.selectBusHandlingById(this.busHandlingId);
    },
    /* 查询实名信息 */
    async selectHandUser() {
      let waterUserNo = '';
      const { status, resultData } = await selectHandUser(waterUserNo);
      if (status === 'complete' && resultData) {
        this.realName.contacts = resultData.handUserVO.wuserName;
        this.realName.telephone = resultData.handUserVO.phone;
        // this.realName.idNo = resultData.handUserVO.idNo;
      }
    },
    /* 获取表单样式 */
    async selectMaxRelease() {
      let arg = {
        businessCode: this.businessCode,
        moduleCode: this.moduleCode,
      };
      const { status, resultData } = await selectMaxRelease(arg);
      if (status === 'complete' && resultData) {
        this.formReleaseId = resultData.formReleaseId;
        let data = JSON.parse(resultData.content);
        if (this.businessState === 'see') {
          data = { ...data, readonly: true };
        }
        this.formConf = data;
        console.log(this.formConf, 'formConf');
        if (this.busHandlingId) {
          this.selectBusHandlingById(this.busHandlingId);
        }
      }
    },
    /* 数据查询 */
    async selectBusHandlingById(id) {
      const { status, resultData } = await selectBusHandlingById(id);
      if (status === 'complete') {
        this.statusInfor = resultData;
        this.stateBoolean = resultData.stateBoolean;
        let data = JSON.parse(resultData.formContent);
        this.realName = {
          contacts: data.contacts,
          // idNo: data.idNo,
          telephone: data.telephone,
        };
        this.$nextTick(() => {
          this.$refs.parser.fillForm(data);
        });
      } else {
        this.statusInfor = null;
      }
    },
    /* 新增提交 */
    async addBusHandling(data) {
      const { status, resultData } = await addBusHandling(data);
      if (status === 'complete') {
        let processingDays = resultData.processingDays;
        this.busHandlingId = resultData.busHandlingId;
        this.handerDialog('提交', processingDays);
      }
    },
    /* 修改更新 */
    async updateBusHandling(arg) {
      const { status, resultData } = await updateBusHandling(arg);
      if (status === 'complete') {
        let processingDays = resultData.processingDays;
        this.busHandlingId = resultData.busHandlingId;
        this.handerDialog('修改', processingDays);
      }
    },
    /* 成功后弹框 */
    handerDialog(dialogName, processingDays) {
      Dialog.confirm({
        title: `您的业务办理已${dialogName}成功！`,
        message:
          processingDays !== undefined
            ? `预计在${processingDays}个工作日内完成，请耐心等待，可至“我的办理”中查看办理进度。`
            : '可至“我的办理”中查看办理进度。',
        confirmButtonText: '查看记录',
        confirmButtonColor: '#3C7EF0',
        cancelButtonText: '返回首页',
        cancelButtonColor: '#767E8C',
      })
        .then(() => {
          // 查看记录
          this.setBusinessNews('see');
          this.key2 = +new Date();
          this.formConf = { ...this.formConf, readonly: true };
          this.selectBusHandlingById(this.busHandlingId);
        })
        .catch(() => {
          // 返回首页
          this.$router.push({ path: '/' });
        });
    },
    /* 业务全局变量修改 */
    setBusinessNews(State) {
      this.businessState = State;
      let item = {
        ...this.businessNews,
        businessState: State,
        busHandlingId: this.busHandlingId,
      };
      store.dispatch('user/setBusinessNews', item);
    },
  },
};
</script>

<style lang="less" scoped>
.vant-parser {
  margin: 16px 16px 180px 16px;
  overflow: hidden;
  // margin-bottom: 400px;
}
.real-infor {
  margin: 16px;
  border-radius: 6px;
  // overflow: hidden;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px 15px 0px;
  background: #ffffff;
  width: 100%;
  position: fixed;
  bottom: 0px;

  .btns {
    width: 100%;
    text-align: center;
    margin: 0px 16px 0px 16px;
    /deep/.van-button--normal {
      width: calc(100% / 2 - 10px);
      border-radius: 8px;
    }
  }
}
</style>
